// const serverAddress = "https://api.odontologia.pe";
const serverAddress = process.env.NODE_ENV === 'production' ? "https://api.odontologia.pe" : "http://localhost:8001";

const testEndPoints = {
    test: `${serverAddress}/test`,
    testPushMessage: `${serverAddress}/test-push-message`,
}
const loginEndPoints = {
    login: `${serverAddress}/login`,
    refreshToken: `${serverAddress}/refresh-token`,
}
const patientsEndPoints = {
    getMedicalTemplates: `${serverAddress}/medical-templates`,
    newPatient: `${serverAddress}/new-patient`,
    deletePatient: `${serverAddress}/delete-patient`,
    getPatients: `${serverAddress}/get-patients`,
    getAllPatients: `${serverAddress}/get-all-patients`,
    getPatientAppointments: `${serverAddress}/get-patient-appointments`,
    getScheduledReminders: `${serverAddress}/get-scheduled-reminders`,
    queryPatients: `${serverAddress}/query-patients`,
    scheduleAppointment: `${serverAddress}/schedule-appointment`,
    getAppointments: `${serverAddress}/get-appointments`,
    getMonthlyAppointments: `${serverAddress}/get-monthly-appointments`,
    updateAppointment: `${serverAddress}/update-appointment`,
    updateAppointmentStatus: `${serverAddress}/update-appointment-status`,
    getReminderTemplates: `${serverAddress}/get-reminder-templates`,
    newReminderTemplate: `${serverAddress}/new-reminder-template`,
    getNextScheduledAppointment: `${serverAddress}/get-next-scheduled-appointment`,
    newCarePlan: `${serverAddress}/new-care-plan`,
    deleteCarePlan: `${serverAddress}/delete-care-plan`,
    getPatientCarePlans: `${serverAddress}/patient-care-plans`,
    updateCarePlan: `${serverAddress}/update-care-plan`,
    updateMedicalHistory: `${serverAddress}/update-medical-history`,
    addPayment: `${serverAddress}/add-payment`,
    getPayments: `${serverAddress}/get-payments`,
    markReminderAsSent: `${serverAddress}/mark-reminder-as-sent`,
    deleteAppointment: `${serverAddress}/delete-appointment`,
    uploadFiles: `${serverAddress}/upload-files`,
    getPatientFiles: `${serverAddress}/get-patient-files`,
    getThumbnail: `${serverAddress}/get-thumbnail`,
    getFile: `${serverAddress}/get-file`,
    deleteFile: `${serverAddress}/delete-file`,
    updatePatientCategory: `${serverAddress}/update-patient-category`,
    createPatientCategory: `${serverAddress}/create-patient-category`,
    updateBusinessCategory: `${serverAddress}/update-business-category`,
    updateBusinessCategories: `${serverAddress}/update-business-categories`,
    deleteBusinessCategory: `${serverAddress}/delete-business-category`,
    updatePatients: `${serverAddress}/update-patients`,
    saveSignedConsentForm: `${serverAddress}/save-signed-consent-form`,
    calculatePatientsMoneySpent: `${serverAddress}/calculate-patients-money-spent`,
}
const productsEndpoints = {
    getProducts: `${serverAddress}/get-products`,
    addProduct: `${serverAddress}/add-product`,
    updateProduct: `${serverAddress}/update-product`,
    removeProduct: `${serverAddress}/remove-product`,
    queryProducts: `${serverAddress}/query-products`,
}
const servicesEndpoints = {
    getServices: `${serverAddress}/get-services`,
    addService: `${serverAddress}/add-service`,
    updateService: `${serverAddress}/update-service`,
    removeService: `${serverAddress}/remove-service`,
}
const staffEndPoints = {
    getDoctors: `${serverAddress}/get-doctors`,
    getMyData: `${serverAddress}/my-data`,
    getMyBusinessData: `${serverAddress}/my-business-data`,
    updateMyBusinessData: `${serverAddress}/update-my-business-data`,
    getMyStaff: `${serverAddress}/my-staff`,
    addStaff: `${serverAddress}/add-staff`,
    updateStaff: `${serverAddress}/update-staff`,
    hasPassword: `${serverAddress}/has-password`,
    setNewPassword: `${serverAddress}/set-new-password`,
    deleteTreatment: `${serverAddress}/delete-treatment`,
    deleteProcedure: `${serverAddress}/delete-procedure`,
    updateWhatsappType: `${serverAddress}/update-whatsapp-type`,
    setReceiveReminderMessages: `${serverAddress}/set-receive-reminder-messages`,
    getDoctorAppointments: `${serverAddress}/get-doctor-appointments`,
    getConnectedUsers: `${serverAddress}/connected-users`,
    getBusinesses: `${serverAddress}/get-businesses`,
    newUserData: `${serverAddress}/new-user-data`,
    createNewProfile: `${serverAddress}/create-new-profile`,
    emailLoginCredentials: `${serverAddress}/email-login-credentials`,
    getCashRegisterInstances: `${serverAddress}/get-cash-register-instances`,
    createCashRegisterInstance: `${serverAddress}/create-cash-register-instance`,
    generateCashRegisterEntries: `${serverAddress}/generate-cash-register-entries`,
    editCashRegisterEntry: `${serverAddress}/edit-cash-register-entry`,
    closeCashRegisterInstance: `${serverAddress}/close-cash-register-instance`,
    getLastCashRegisterInstance: `${serverAddress}/get-last-cash-register-instance`,
    generatePaymentRequest: `${serverAddress}/generate-payment-request`,
    generatePayment: `${serverAddress}/generate-payment`,
    getRevenue: `${serverAddress}/get-revenue`,
    getPaymentRequests: `${serverAddress}/get-payment-requests`,
    generateCashRegisterEntryInThePast: `${serverAddress}/generate-cash-register-entry-in-the-past`,
    deleteCashRegisterEntry: `${serverAddress}/delete-cash-register-entry`,
    getAllStaff: `${serverAddress}/get-all-staff`,
    getCashRegisterInstanceByEntry: `${serverAddress}/get-cash-register-instance-by-entry`,
    updateProcedure: `${serverAddress}/update-procedure`,
    getConsentFormsTemplates: `${serverAddress}/get-consent-forms-templates`,
    newConsentFormTemplate: `${serverAddress}/new-consent-form-template`,
    editConsentFormTemplate: `${serverAddress}/edit-consent-form-template`,
    deleteConsentFormTemplate: `${serverAddress}/delete-consent-form-template`,
    getImage: `${serverAddress}/get-image`,
    getAppointmentsByDate: `${serverAddress}/get-appointments-by-date`,
    
}

const generalEndpoints = {
    sendError: `${serverAddress}/send-error`,
}

module.exports = {
    serverAddress,
    testEndPoints,
    loginEndPoints,
    patientsEndPoints,
    productsEndpoints,
    servicesEndpoints,
    staffEndPoints,
    generalEndpoints,
}