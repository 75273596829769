import { useGlobals } from "src/context/globals-context";

const GeneralPostRequests = () => {

    const { loginEndPoints, fetchData, jwtRef, generalEndpoints } = useGlobals();

    const loginAPI = async (businessUsername, username, password, rememberMe) => {
        try {
            const data = await fetchData(loginEndPoints.login, {
                method: "POST",
                headers: {'Content-Type': 'application/json'}, 
                body: JSON.stringify({businessUsername, username, password, rememberMe})
            })
            return data;
        } catch (err) {
            throw new Error(err.message);
        }
    }

    const sendError = async (errorData) => {
        try {
            await fetchData(generalEndpoints.sendError, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer: ${jwtRef.current}`
                },
                body: JSON.stringify({errorData})
            })
        } catch (err) {
            throw new Error(err.message);
        }
    }

    return {
        loginAPI,
        sendError,
    }
}

export default GeneralPostRequests;