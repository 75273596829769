import ErrorModal from './ErrorModal'; // Your modal component

import { useState, useEffect } from "react";

const useErrorBoundary = () => {

	const [error, setError] = useState(null);

	const resetError = () => setError(null); // To reset the error after displaying the modal

	const catchError = (error) => {
		const myData       = JSON.parse(localStorage.getItem('myData'));
		const businessName = myData.business.name;
		const staff        = myData.fullName;
		const errorData    = {
			message: error.message,
			stack: error.stack,
			time: new Date(),
            businessName,
            staff
		};

		setError(errorData);
	};

	return { error, catchError, resetError };
};

const ErrorBoundary = ({ children }) => {
	const { error, catchError, resetError } = useErrorBoundary();

	// Effect to catch errors globally
	useEffect(() => {
		const errorHandler = (event) => {
			catchError(event.error);
			// Prevent default error handling
			event.preventDefault();
		};

		window.addEventListener("error", errorHandler);
		window.addEventListener("unhandledrejection", errorHandler);

		return () => {
			window.removeEventListener("error", errorHandler);
			window.removeEventListener("unhandledrejection", errorHandler);
		};
	}, [catchError]);

	if (error) {
		return (
			<>
				<ErrorModal
                    error={error}
                    onClose={() => resetError()} // Reset error after closing modal
                />
			</>
		);
	}

	return children;
};

export default ErrorBoundary;
