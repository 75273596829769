import { useState, useEffect } from "react";

import { useGlobals } from "src/context/globals-context";
import GeneralPostRequests from "src/api/post/GeneralPostRequests";

import "./ErrorModal.scss";
import { useNavigate, useLocation } from "react-router-dom";

const ErrorModal = ({ error }) => {

	const { isMobileRef } = useGlobals();
	const { sendError } = GeneralPostRequests();

	const [ text, SetText ] = useState("");

    const navigate = useNavigate();
    const location = useLocation();

    const handleClick = () => {
        try {

            const errorMessage = {
                time: new Date(),
                businessName: error.businessName,
                staff: error.staff,
                message: text
            };

            if(text) sendError(errorMessage);

           

        } finally {
            function clearParams () {
                const params = new URLSearchParams(location.search);
                
                params.forEach((_, key) => {
                    params.delete(key);
                });
                navigate(`calendar-page`, { replace: true });

                window.location.reload();
            };

            clearParams();
        }
    };

	useEffect(() => {
		sendError(error);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={`error-modal ${isMobileRef.current ? "mobile" : ""}`}>
            <div className="container">
                <div className="image-container">
                    <img src="images/icons/dead_tooth.svg" alt="" />
                </div>

                <h1 className="oops text-[80px] text-[#a2e0e1]">Ups!</h1>
                <div className="text-gray-700 mb-12 lg:mb-4">...hubo un error.</div>

                <div className="mb-5 text-gray-700">
                    Por favor, ayúdanos a mejorar
                    <br />
                    Describe exactamente lo que llevo a este error:
                </div>
                <textarea value={text} onChange={e => SetText(e.currentTarget.value)} placeholder="Ej. Estaba llenando el tratamiento del paciente Jorge Augusto Castillo y cuando intenté crear un nuevo procedimiento..." />
                <div className="button--container">
                    <button className="button--blue" onClick={handleClick}>
                        Enviar y reiniciar la aplicación
                    </button>
                </div>
            </div>
		</div>
	);
};

export default ErrorModal;
